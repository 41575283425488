import { createAction, props } from '@ngrx/store';

export const loginAction = createAction(
    '[user] login',
    props<{ user: User }>()
);

export const logoutAction = createAction('[user] logout');

export interface User {
    id: number;
    emailAddress: string;
    firstName: string;
    lastName: string;
    status: string;
    profileImage: string;
}
